<template>
  <div class="etape-setting">
    <div class="header">
      <v-row>
        <v-col cols="5">
          <div class="style-title-setting-global">
            {{
              $i18n.locale == 'fr'
                ? 'Gestion des pipelines'
                : 'Pipeline Management'
            }}
          </div>

          <div
            v-if="
              getEtapesError &&
                !ModalEtapesDelete &&
                !addEtapeModal &&
                !updateEtapeModal
            "
            class="error-msg mt-2"
          >
            <ul v-if="Array.isArray(getEtapesError)">
              <li v-for="(e, index) in getEtapesError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getEtapesError }}</span>
          </div>
        </v-col>
        <v-col cols="">
          <div class="flex-center">
            <v-progress-circular
              v-if="getEtapesLoading && !initLoading && !loading"
              indeterminate
              class="mr-3"
              color="#5C2DD3"
            ></v-progress-circular>
            <v-select
              class="select-type-projet-step mr-2"
              v-model="type_id"
              :label="$i18n.locale == 'fr' ? 'Type du projet' : 'Project type'"
              dense
              :no-data-text="
                $i18n.locale == 'fr'
                  ? 'Aucun Type du projet trouvé'
                  : 'None Project type found'
              "
              item-text="name"
              item-value="id"
              :items="getProjectsTypes"
              @change="handelUpdateEtapeParTypeProjet(type_id)"
              outlined
              :loading="getProjectsTypesLoading"
              :persistent-placeholder="true"
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              color="#5C2DD3"
              item-color="#5C2DD3"
            ></v-select>
            <v-btn
              class="btn-filter mb-1 float-right"
              dark
              color="#5C2DD3"
              @click.prevent.stop="handleClickBtnAddEtape"
            >
              <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
              {{ $i18n.locale == 'fr' ? 'Ajouter une étape' : 'Add step' }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <div v-if="etapesPerProjectsTypes && etapesPerProjectsTypes.length">
        <v-list dense class="list-ensemble mt-3">
          <Container
            :drop-placeholder="dropPlaceholderOptions"
            :lock-axis="'y'"
            @drop="onColumnDropProjects"
            drag-handle-selector=".column-drag-container"
          >
            <Draggable
              v-for="(etape, index) in etapesPerProjectsTypes"
              :key="index"
              :value="index.id"
            >
              <v-list-item color="#5C2DD3" class="list-ensemble-item">
                <div class="block-flex-align-space-between">
                  <v-list-item-action class="action-btn pr-3">
                    <v-btn
                      icon
                      class="cursor-move mt-0"
                      :class="{
                        'column-drag-container':
                          getEtapes && getEtapes.length > 1
                      }"
                    >
                      <v-icon>mdi-drag-horizontal-variant</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div
                        class="font-text font-sz-14 bold-700 text-capitalize"
                      >
                        {{ etape.index }}- {{ etape.name }}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="action-btn">
                    <v-btn
                      class="icon-action mr-2"
                      small
                      outlined
                      color="#5C2DD3"
                      :title="
                        $i18n.locale == 'fr'
                          ? 'Configuration étape'
                          : 'Step configuration'
                      "
                      @click.prevent.stop="handleSettingClick(etape)"
                    >
                      <v-icon>mdi-cog-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action class="action-btn">
                    <v-btn
                      class="icon-action mr-2"
                      small
                      outlined
                      color="#5C2DD3"
                      :title="$t('update')"
                      @click.prevent.stop="handleUpdateClick(etape)"
                    >
                      <font-awesome-icon class="img-h-19" icon="pencil-alt" />
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action class="action-btn">
                    <v-btn
                      class="icon-action"
                      :title="$t('deleted')"
                      small
                      outlined
                      color="#5C2DD3"
                      @click.prevent.stop="handleDeleteClick(etape)"
                    >
                      <font-awesome-icon class="img-h-19" icon="trash-alt" />
                    </v-btn>
                  </v-list-item-action>
                </div>
              </v-list-item> </Draggable
          ></Container>
        </v-list>
      </div>
      <div
        v-if="
          (!initLoading && !getEtapesLoading && !etapesPerProjectsTypes) ||
            !etapesPerProjectsTypes.length
        "
        class="div-aucun-list"
      >
        <div class="titre">
          {{ $i18n.locale == 'fr' ? 'Aucune étape' : 'No step' }}
        </div>
      </div>
      <div
        class="footer-style-table"
        v-if="etapesPerProjectsTypes && etapesPerProjectsTypes.length"
      >
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            :label="
              $i18n.locale === 'fr' ? 'Eléments par page' : 'Rows per page'
            "
            outlined
            dense
            hide-details
            :no-data-text="
              $i18n.locale === 'fr' ? 'Aucun élément trouvé' : 'No items found'
            "
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div>
    </div>
    <!-- Add ETAPE Modal -->
    <v-dialog v-model="addEtapeModal" max-width="800" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Ajouter une étape' : 'Add step' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addEtapeModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addEtapeModal" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="etapesToAdd.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="etapesToAdd.type"
                  :label="$t('typeProjet')"
                  dense
                  :no-data-text="
                    $t('noDataOptionMsg', { option: $t('typeProjet') })
                  "
                  item-text="name"
                  item-value="id"
                  chips
                  return-object
                  multiple
                  :deletable-chips="true"
                  :small-chips="true"
                  :items="getProjectsTypes"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  clearable
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: $t('typeProjet') })
                  ]"
                  class="msg-error"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getEtapesLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getEtapesError" class="error-msg">
              <ul v-if="Array.isArray(getEtapesError)">
                <li v-for="(e, index) in getEtapesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getEtapesError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmit"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addEtapeModal')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add ETAPE Modal -->

    <!-- UPDATE ETAPE Modal -->
    <v-dialog v-model="updateEtapeModal" max-width="800" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Modifier une étape' : 'Edit step' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('updateEtapeModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="updateEtapeModal" class="mt-2" v-if="etapesToUpdate">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="etapesToUpdate.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="etapesToUpdate.type"
                  :label="$t('typeProjet')"
                  dense
                  :no-data-text="
                    $t('noDataOptionMsg', { option: $t('typeProjet') })
                  "
                  item-text="name"
                  item-value="id"
                  chips
                  return-object
                  multiple
                  :deletable-chips="true"
                  :small-chips="true"
                  :items="getProjectsTypes"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  clearable
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: $t('typeProjet') })
                  ]"
                  class="msg-error"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getEtapesLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getEtapesError" class="error-msg">
              <ul v-if="Array.isArray(getEtapesError)">
                <li v-for="(e, index) in getEtapesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getEtapesError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitUpdate"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('updateEtapeModal')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END UPDATE ETAPE Modal -->

    <!-- Delete ETAPE Modal -->
    <v-dialog v-model="ModalEtapesDelete" max-width="700" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Supprimer une étape' : 'Delete step' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalEtapesDelete')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="parg">
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? "l'étape :" : 'the step :'
              })
            }}
            <strong class="text-capitalize">
              {{ etapesToDelete ? etapesToDelete.name : '' }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getEtapesLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getEtapesError" class="error-msg">
              <ul v-if="Array.isArray(getEtapesError)">
                <li v-for="(e, index) in getEtapesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getEtapesError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleModalDelete"
            :loading="getEtapesLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalEtapesDelete')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete ETAPE Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { Container, Draggable } from 'vue-smooth-dnd'
export default {
  name: 'etapes',
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      selectedPipeline: null,
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      etapesToAdd: {
        name: null,
        type: null
      },
      etapesToUpdate: null,
      etapesToUpdateOrigin: null,
      type_id: null,
      etapesToDelete: null,
      initLoading: true,
      addEtapeModal: false,
      updateEtapeModal: false,
      ModalEtapesDelete: false,
      loading: false,
      page: 1,
      per_page: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ]
    }
  },

  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchEtapes',
      'addEtapes',
      'deleteEtapes',
      'resetErrorEtapes',
      'updateOrderEtapes',
      'moveEtapes',
      'updateEtape'
    ]),
    pagination(pagination) {
      this.page = pagination
      this.fetchEtapes({
        page: this.page,
        per_page: this.per_page,
        type_id: this.type_id
      })
    },
    changePerPage() {
      this.fetchEtapes({
        page: this.page,
        per_page: this.per_page,
        type_id: this.type_id
      })
    },
    handelCancel() {
      this.$router.push('/setting/pipeline')
    },
    async handelUpdateEtapeParTypeProjet(type_id) {
      this.initLoading = true
      await this.fetchEtapes({
        page: this.page,
        per_page: this.per_page,
        type_id: type_id
      })
      localStorage.setItem('typeProjetStep', type_id)
      this.initLoading = false
    },
    onColumnDropProjects(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let orderEtape = this.applyDrag(this.getEtapes, dropResult)
      this.updateOrderEtapes(orderEtape, this.getEtapes[addedIndex])
      this.moveEtapes({
        type_id: this.type_id,
        etapes: this.getEtapes[addedIndex],
        new_ordre: addedIndex
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    handleClickBtnAddEtape() {
      this.resetErrorEtapes()
      this.addEtapeModal = true
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addEtapeModal') {
        this.$refs.addEtapeModal.resetValidation()
      }
      if (ref == 'updateEtapeModal') {
        this.$refs.updateEtapeModal.resetValidation()
      }
      this.resetErrorEtapes()
      this.resetModal()
    },
    resetModal() {
      this.etapesToAdd = {
        name: null,
        type: null
      }
    },
    handleUpdateClick(etape) {
      this.etapesToUpdate = { ...etape }
      this.etapesToUpdateOrigin = etape
      this.updateEtapeModal = true
    },
    handleSettingClick(etape) {
      this.$router.replace('/setting/pipeline/' + etape.id)
    },
    async handleSubmitUpdate() {
      if (this.$refs.updateEtapeModal.validate()) {
        this.loading = true
        const body = {
          types:
            this.etapesToUpdate.type && this.etapesToUpdate.type.length
              ? this.etapesToUpdate.type.map(item => item.id)
              : [],
          name: this.etapesToUpdate.name
        }
        const response = await this.updateEtape({
          body,
          etape: this.etapesToUpdateOrigin
        })
        if (response) {
          this.closeDialog('updateEtapeModal')
        }
        this.loading = false
      }
    },
    handleDeleteClick(etapeDelete) {
      this.etapesToDelete = { ...etapeDelete }
      this.ModalEtapesDelete = true
    },
    async handleSubmit() {
      if (this.$refs.addEtapeModal.validate()) {
        this.loading = true
        const body = {
          types:
            this.etapesToAdd.type && this.etapesToAdd.type.length
              ? this.etapesToAdd.type.map(item => item.id)
              : [],
          name: this.etapesToAdd.name
        }
        const response = await this.addEtapes(body)
        if (response) {
          this.closeDialog('addEtapeModal')
        }
        this.loading = false
      }
    },
    async handleModalDelete() {
      const response = await this.deleteEtapes(this.etapesToDelete.id)
      if (response) {
        this.closeDialog('ModalEtapesDelete')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getEtapes',
      'getProjectsTypes',
      'getEtapesLoading',
      'getEtapesError',
      'getProjectsTypesLoading',
      'countPaginationEtapes'
    ]),
    totalPages() {
      if (this.countPaginationEtapes) {
        return Math.ceil(this.countPaginationEtapes / this.per_page)
      }
      return this.countPaginationEtapes
    },
    etapesPerProjectsTypes: function() {
      return this.getEtapes.filter(etapes =>
        etapes.type.some(element => element.id === this.type_id)
      )
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchProjetsTypes()
    if (this.getProjectsTypes && this.getProjectsTypes.length) {
      this.type_id = this.getProjectsTypes[0].id
      this.fetchEtapes({
        page: this.page,
        per_page: this.per_page,
        type_id: this.type_id
      })
    }
    localStorage.setItem('typeProjetStep', this.type_id)
    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.etape-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .smooth-dnd-container.horizontal {
    display: flex;
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    margin-bottom: 15px;
    border: 2px solid #5c2dd3;
    border-left: 15px solid #5c2dd3;
    background-color: #f0ecfa !important;
    border-radius: 4px;
    padding: 5px;
  }
  .smooth-dnd-container {
    margin: 3px;
  }
  .smooth-dnd-container {
    margin: 0px 6px 0px 0px;
    position: relative;
    min-height: 30px;
    min-width: 255px;
  }
  .smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    border: 1px solid #d8d8d8;
    margin-right: 8px;
    background-color: #fff;
    border-radius: 4px;
  }
  .list-ensemble-item {
    display: block;
    // border-top: 0px solid #d8d8d8;
    // border-bottom: 1px solid #d8d8d8;
    // border-left: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
    // background-color: #fff;
    // margin-bottom: 6px;
    .action-btn {
      margin: 4px 0px !important;
    }
    .v-list-item__content {
      padding: 0px 0 !important;
    }
  }
  .list-ensemble {
    max-height: calc(100vh - 266px) !important;
    height: calc(100vh - 266px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }

  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  //   .content-members {
  //     padding-top: 8px;
  //   }
  .list-member {
    margin: 9px 0px;
    li {
      margin-bottom: 6px;
    }
  }
  .card-list {
    box-shadow: unset !important;
  }
}
</style>

<style lang="scss">
.etape-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
      padding: 0 7px !important;
    }
  }
}
</style>
